import { Header } from "@/src/components/header";

export const PageLayout = ({ children }) => {
  return (
    <div className="bg-gradient-to-t from-primary to-secondary min-h-screen">
      <Header />

      <main>{children}</main>
    </div>
  );
};
