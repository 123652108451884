import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { AdminPage } from "@/src/pages/admin";
import { Home } from "@/src/pages/home";
import { PageLayout } from "@/src/layouts/pageLayout";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PageLayout>
        <Home />
      </PageLayout>
    ),
  },
  {
    path: "/0x17f2deed107a4e114ee847e1e89d900b21acfe28",
    element: (
      <PageLayout>
        <AdminPage />
      </PageLayout>
    ),
  },
]);
export const Routes = () => {
  return <RouterProvider router={router} />;
};
