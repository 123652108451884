import { Link } from "react-router-dom";
// Custom Components Imports
import { ConnectButton } from "./connectButton";
// Icons & Images Imports
import logoIcon from "@/src/assets/logo.png";
import { Button } from "@/src/components/button";

export const Header = () => {
  return (
    <header className="w-full py-4 min-h-20">
      <div className="px-5 max-w-[82rem] mx-auto w-full flex items-center">
        <div className="flex justify-between items-center px-[1.33rem] w-full">
          <span className="basis-1/2 md:basis-1/3 ">
            <a href="https://songbird.canary-reborn.xyz/"><img src={logoIcon} alt="Logo" className="h-14 w-14 bg-contain" /></a>
            </span>
          <div className="hidden md:block space-x-6 items-center gap-5">
            <Link
              to="/"
              className="text-black text-lg font-semibold py-1 transition-all hover:pb-1.5 border-b-2 border-primary"
            >
              Canary Reborn Vault
            </Link>
          </div>
          <div className="basis-1/2 md:basis-1/3 text-right ">
            <ConnectButton />
          </div>
        </div>
      </div>
    </header>
  );
};
