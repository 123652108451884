import VAULT_ABI from "@/src/assets/abis/canaryVault.json";
import ERC721_ABI from "@/src/assets/abis/IERC721.json";
import ERC20_ABI from "@/src/assets/abis/ERC20.json";

const VAULT_ADDRESS = "0x33B9798cBEC5D9D31124972aE5caf9B77E7585B8";
const ERC721_ADDRESS = "0x17F2DEEd107A4e114eE847E1e89d900B21ACfe28";

const ERC20_ADDRESS = "0x02f0826ef6aD107Cfc861152B32B52fD11BaB9ED";
const ERC20_SYMBOL = "WSGB";

export {
  ERC20_ABI,
  ERC20_ADDRESS,
  ERC20_SYMBOL,
  ERC721_ADDRESS,
  ERC721_ABI,
  VAULT_ADDRESS,
  VAULT_ABI,
};
