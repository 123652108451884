import React from "react";
import { useAccount, usePublicClient, useWriteContract } from "wagmi";
import { Button } from "@/src/components/button";
import { useHomePageContext } from "@/src/context/homePageContext";
import {
  ERC20_ABI,
  ERC20_ADDRESS,
  VAULT_ABI,
  VAULT_ADDRESS,
} from "@/src/lib/constants";
import toast from "react-hot-toast";
import { AdminRow } from "./components/adminRow";

const codes = {
  2: "Created",
  3: "Repaid",
  4: "Liquidated",
};

const loanStatusCodeToText = (codeId) => {
  return codes[codeId] || null;
};

export const Admin = () => {
  const { loans, allLoans, getAddressLoans, getNFTsIds, getLiquidityInfo, getAllLoans } =
    useHomePageContext();
  const publicClient = usePublicClient();
  const { address } = useAccount();
  const { writeContractAsync } = useWriteContract();
 
  const filteredLoans = allLoans.filter((loan) => loan.deadline < Date.now()/1000);
  const filteredAllLoans = filteredLoans.filter((loan) => loan.status == 2);

  getAllLoans();
  const onLiquidate = () => {
    const action = new Promise(async (resolve, reject) => {
      try {
        const txId = await writeContractAsync({
          abi: VAULT_ABI,
          address: VAULT_ADDRESS,
          functionName: "liquidateAll",
        });

        await publicClient.waitForTransactionReceipt({
          hash: txId,
          confirmations: 2,
        });
        getAllLoans();

        resolve();
      } catch (err) {
        reject(err?.shortMessage || err.message || "Something went wrong");
      }
    });
    

    toast.promise(action, {
      loading: "Transaction is in progress",
      success: "Liquidated loans successfully",
      error: (error) => error,
    });
  };
 
  return (
    <section className="max-w-5xl mx-auto px-5 pb-10">
      <div className="bg-card shadow-[0px_2px_4px] shadow-black/10 rounded-md">
        <div className="flex items-center justify-between py-4 px-5 border-b border-black/5 gap-2" >
          <h4 className="font-medium text-xl md:text-2xl">Liquidateable Loans
          </h4>
          <Button
                  onClick={onLiquidate.bind(this)}
                  className="w-20 capitalize font-normal h-10 py-1"
                  variant="secondary"
                >
                  Liquidate
          </Button>
        </div>
        <div className="flex py-4 px-6 border-b border-black/5 font-medium">
          <div className="basis-1/3 md:basis-1/5">
            <p className="text-sm md:text-base">Loan ID</p>
          </div>
          <div className="basis-1/3 md:basis-1/4 text-center">
            <p className="text-sm md:text-base">Debt</p>
          </div>
          <div className="hidden md:inline-block basis-1/2 text-center">
            <p className="text-sm md:text-base">Deadline</p>
          </div>
          <div className="basis-1/2 md:basis-1/4">
            <p className="text-center">Action</p>
          </div>
        </div>

        <div>
          {filteredAllLoans.map((loan) => (
            <AdminRow key={loan.loanId} {...loan} />
          ))}
        </div>
      </div>
    </section>
  );
};
