// Songbird configuration
export const activeChain = {
  chainId: "0x13", 
  chainName: "Songbird Canary Network",
  nativeCurrency: {
    symbol: "SGB",
    decimals: 18,
  },
  blockExplorerUrls: ["https://songbird-explorer.flare.network/"],
  rpcUrls: ["https://songbird.solidifi.app/ext/C/rpc"],
};
