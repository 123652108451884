import { useEffect } from "react";
import { cn } from "@/src/lib/utils";
import { ERC20_SYMBOL } from "@/src/lib/constants";
import { useHomePageContext } from "@/src/context/homePageContext";

export const LiquidityInfo = ({ className }) => {
  const { liquidity, getLiquidityInfo } = useHomePageContext();

  useEffect(() => {
    getLiquidityInfo();
  }, [getLiquidityInfo]);

  return (
    <div className={cn("w-full md:text-center mb-4 md:mb-0", className)}>
      <h2 className="text-foreground text-2xl font-semibold">
        Available Liquidity
      </h2>
      <div className="md:flex items-center justify-center">
        <p className="inline-flex gap-1 text-xl font-semibold">
          <span>{parseFloat(liquidity).toFixed(0)}</span>
          <span>{ERC20_SYMBOL}</span>
        </p>
      </div>
    </div>
  );
};
