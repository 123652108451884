import React, { useRef, useState, useEffect } from "react";
import { useAccount, usePublicClient, useWriteContract } from "wagmi";
import toast from "react-hot-toast";
import { useHomePageContext } from "@/src/context/homePageContext";
import * as Dialog from "@radix-ui/react-dialog";
import { Button } from "@/src/components/button";

import {
  ERC721_ABI,
  ERC721_ADDRESS,
  VAULT_ABI,
  VAULT_ADDRESS,
} from "@/src/lib/constants";

export const LendPopup = ({ open, onClose = () => null }) => {
  const publicClient = usePublicClient();
  const { writeContractAsync } = useWriteContract();
  const { address } = useAccount();
  const { NFTsIds, approvedAll, getApprovedAll,setNFTsIds, getAddressLoans, getLiquidityInfo } =
    useHomePageContext();
  getApprovedAll(address);
  // hook to prevent tx call if one is already in progress
  const isCallInProgress = useRef(false);

  useEffect(() => {
    getApprovedAll(address);
  }, [approvedAll, getApprovedAll]);

  const onSubmit = (event) => {
    event.preventDefault();
    onClose();
    const form = new FormData(event.target);

    const tokenId = form.get("tokenId");
    if (!tokenId) return toast.error("Invalid token Id");

    if (isCallInProgress.current) return;
    isCallInProgress.current = true;
    
    const action = new Promise(async (resolve, reject) => {
      try {
        const tokenApprovals = await publicClient.readContract({
          abi: ERC721_ABI,
          address: ERC721_ADDRESS,
          functionName: "getApproved",
          args: [tokenId],
        });
        const collectionApprovals = await publicClient.readContract({
          abi: ERC721_ABI,
          address: ERC721_ADDRESS,
          functionName: "isApprovedForAll",
          args: [address, VAULT_ADDRESS],
        });
        console.log(String(tokenApprovals).toLowerCase());
        console.log(VAULT_ADDRESS.toLocaleLowerCase());
        console.log(collectionApprovals);
        if ((
          String(tokenApprovals).toLowerCase() !==
          VAULT_ADDRESS.toLocaleLowerCase()
        ) && collectionApprovals == false){
          const txId = await writeContractAsync({
            abi: ERC721_ABI,
            address: ERC721_ADDRESS,
            functionName: "approve",
            args: [VAULT_ADDRESS, tokenId],
          });

          await publicClient.waitForTransactionReceipt({
            hash: txId,
            confirmations: 2,
          });
        }

        const txId = await writeContractAsync({
          abi: VAULT_ABI,
          address: VAULT_ADDRESS,
          functionName: "borrowWsgb",
          args: [tokenId],
        });

        await publicClient.waitForTransactionReceipt({
          hash: txId,
          confirmations: 2,
        });



        setNFTsIds((prev) =>
          prev.filter((_tokenId) => parseInt(_tokenId) !== parseInt(tokenId))
        );

        getAddressLoans(address);
        getLiquidityInfo();
        resolve();
        
      } catch (err) {
        reject(err?.shortMessage || err.message || "Something went wrong");
      }

      isCallInProgress.current = false;
    });
    toast.promise(action, {
      loading: "Transaction is in progress",
      success: "Successfully borrowed WSGB",
      error: (error) => error,
    });
  };

  const onApproveAll = () => {
    onClose();
    const action = new Promise(async (resolve, reject) => {
      try {

          const txId = await writeContractAsync({
            abi: ERC721_ABI,
            address: ERC721_ADDRESS,
            functionName: "setApprovalForAll",
            args: [VAULT_ADDRESS, true],
          });

          await publicClient.waitForTransactionReceipt({
            hash: txId,
            confirmations: 2,
          });

        resolve();
      } catch (err) {
        reject(err?.shortMessage || err.message || "Something went wrong");
      }
    });
    getApprovedAll(address);

    toast.promise(action, {
      loading: "Transaction is in progress",
      success: "Approved Entire Collection",
      error: (error) => error,
    });
  };
  const onRevokeAll = () => {
    onClose();
    const action = new Promise(async (resolve, reject) => {
      try {

          const txId = await writeContractAsync({
            abi: ERC721_ABI,
            address: ERC721_ADDRESS,
            functionName: "setApprovalForAll",
            args: [VAULT_ADDRESS, false],
          });

          await publicClient.waitForTransactionReceipt({
            hash: txId,
            confirmations: 2,
          });

        resolve();
      } catch (err) {
        reject(err?.shortMessage || err.message || "Something went wrong");
      }
    });
    getApprovedAll(address);

    toast.promise(action, {
      loading: "Transaction is in progress",
      success: "Revoked approval on Collection",
      error: (error) => error,
    });
  };
  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-black/20 data-[state=open]:animate-overlay-show fixed inset-0" />
        <Dialog.Content className="data-[state=open]:animate-content-show fixed top-[50%] left-[50%] max-h-[85vh] w-auto max-w-[90vw] -translate-x-1/2 -translate-y-1/2 rounded-sm bg-white p-6 focus:outline-none">
          <Dialog.Title className="m-0 text-lg font-semibold">
            Borrow SGB
          </Dialog.Title>
          <Dialog.Description className="text-mauve11 mt-[10px] mb-5 text-[15px] leading-normal">
            <div>Collaterize the following NFTs and receive WSGB for a limited duration.</div>
            <div>In case you do not pay back in time, your loan will be liquidated and your NFT will be auctioned.</div>
            <div> </div>
            <div classname="py-2">
              <div>Interest Rate: 0.4%</div>
            <div>Duration: 7 days</div>
            </div>
          </Dialog.Description>

          <form onSubmit={onSubmit}>
            <h2 className="mb-2 text-lg font-medium">NFT IDs</h2>
            <select
              name="tokenId"
              className="w-full p-2 rounded-sm bg-white/90 border border-black/50 outline-0 focus-within:border-primary"
            >
              {NFTsIds?.map((id) => (
                <option key={id} value={parseInt(id)}>
                  {parseInt(id)}
                </option>
              ))}
            </select>

            <div className="mt-[25px] flex justify-end gap-3">
              {!approvedAll && 
            <Button type="button" onClick={onApproveAll} className="w-auto min-w-20">
                Approve Collection
            </Button>
            }
              {approvedAll && 
            <Button type="button" onClick={onRevokeAll} className="w-auto min-w-20">
                Revoke ApproveAll
            </Button>
            }
              <Button type="submit" className="w-auto min-w-20">
                Borrow
              </Button>
              
              <Button
                type="button"
                variant="secondary"
                className="w-auto min-w-20"
                onClick={onClose}
              >
                Cancel
              </Button>
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
