import { useEffect, useState } from "react";
import { usePublicClient } from "wagmi";
import { cn } from "@/src/lib/utils";
import { VAULT_ABI, VAULT_ADDRESS } from "@/src/lib/constants";

export const InterestRate = ({ className }) => {
  const publicClient = usePublicClient();

  const [apy, setAPY] = useState(0);

  useEffect(() => {
    publicClient
      .readContract({
        abi: VAULT_ABI,
        address: VAULT_ADDRESS,
        functionName: "interest",
      })
      .then((interest) => {
        setAPY(parseInt(interest) / 10000);
      })
      .catch(console.log);
  }, [publicClient]);
  return (
    <div className={cn("w-full md:text-center mb-4 md:mb-0", className)}>
      <h2 className="text-foreground text-2xl font-semibold">Interest Rate</h2>
      <div className="md:flex items-center justify-center">
        <p className="inline-flex gap-1 text-xl font-semibold">
          <span>{parseFloat(apy).toFixed(2)} %</span>
        </p>
      </div>
    </div>
  );
};
