import { useEffect, useState } from "react";
import { Button } from "@/src/components/button";
import { LendPopup } from "./lendPopup";
import { usePublicClient, useWriteContract } from "wagmi";
import { formatEther } from "viem";
import { useHomePageContext } from "@/src/context/homePageContext";
import {
  ERC721_ABI,
  ERC721_ADDRESS,
  VAULT_ABI,
  VAULT_ADDRESS,
} from "@/src/lib/constants";

export const LendButton = () => {
  const publicClient = usePublicClient();

  const [isOpenLend, setIsOpenLend] = useState(false);
  const [floorPrice, setFloorPrice] = useState(0);
  const { liquidity, getLiquidityInfo } = useHomePageContext();
  const { writeContractAsync } = useWriteContract();

  useEffect(() => {
    publicClient
      .readContract({
        abi: VAULT_ABI,
        address: VAULT_ADDRESS,
        functionName: "floorPrice",
      })
      .then((result) => {
        setFloorPrice(formatEther(result));
      })
      .catch(console.log);
  }, [publicClient]);

  useEffect(() => {
    getLiquidityInfo();
  }, [getLiquidityInfo]);


  return (
    <>
      <LendPopup open={isOpenLend} onClose={() => setIsOpenLend(false)} />
      <div className="flex flex-col gap-5 items-center justify-center mt-8">
        <Button
          className="max-w-32 text-lg w-full h-auto py-2 capitalize"
          onClick={() => setIsOpenLend(true)}
          disabled={parseInt(floorPrice) > parseInt(liquidity)}
        >
          Borrow
        </Button>

        <div>
          <p className="font-medium text-lg">
             {floorPrice} WSGB / NFT
          </p>
        </div>
      </div>
    </>
  );
};
