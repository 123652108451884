import { HomePageContextProvider } from "../context/homePageContext";
import { MyLoans, Overview } from "../views/home";

export const Home = () => {
  return (
    <HomePageContextProvider>
      <Overview />
      <MyLoans />
    </HomePageContextProvider>
  );
};
