import { HomePageContextProvider } from "../context/homePageContext";
import { MyLoans, Overview } from "../views/home";
import { Admin } from "../views/home/loansInfo/admin";

export const AdminPage = () => {
  return (
    <HomePageContextProvider>
      <Admin />
    </HomePageContextProvider>
  );
};
