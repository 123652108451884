import React from "react";
import { useAccount, usePublicClient, useWriteContract } from "wagmi";
import { Button } from "@/src/components/button";
import { useHomePageContext } from "@/src/context/homePageContext";
import { ExtendPopup } from "./extendPopup";
import { RepayPopup } from "./repayPopup";
import { useState } from "react";
import {
  ERC20_ABI,
  ERC20_ADDRESS,
  VAULT_ABI,
  VAULT_ADDRESS,
} from "@/src/lib/constants";
import toast from "react-hot-toast";
import { TableRow } from "./components/tableRow";

const codes = {
  2: "Created",
  3: "Repaid",
  4: "Liquidated",
};

const loanStatusCodeToText = (codeId) => {
  return codes[codeId] || null;
};

export const MyLoans = () => {
  const { loans, debt, getDebt, wsgbBalance, getBalance, interest, getTotalInterest, getAddressLoans, getNFTsIds, getLiquidityInfo } =
    useHomePageContext();
  const publicClient = usePublicClient();
  const { address } = useAccount();
  const { writeContractAsync } = useWriteContract();
  const [isOpenExtend, setIsOpenExtend] = useState(false);
  const [isOpenRepay, setIsOpenRepay] = useState(false);

  const filteredLoans = loans.filter((loan) => loan.status == 2);
  getDebt(address);
  getBalance(address);
  getTotalInterest(address);

  const onRepay = (loanId) => {
    const loanInfo = loans.filter((loan) => loan.loanId === loanId)[0];
    if (!loanInfo) return toast.error("Loan not found");

    const _interest = loanInfo.interest;
    const _amount = loanInfo.amount;

    const action = new Promise(async (resolve, reject) => {
      try {
        const platformFee = parseInt(
          await publicClient.readContract({
            abi: VAULT_ABI,
            address: VAULT_ADDRESS,
            functionName: "platformFee",
          })
        ).toLocaleString("fullwide", {
          useGrouping: false,
        });

        const _fee = (_interest * platformFee) / 1000000;

        // multiplying each value by 1 to convert from string to number
        const amountToRepay = parseInt(
          _interest * 1 + _fee * 1 + _amount * 1
        ).toLocaleString("fullwide", {
          useGrouping: false,
        });

        const allowance = await publicClient.readContract({
          abi: ERC20_ABI,
          address: ERC20_ADDRESS,
          functionName: "allowance",
          args: [address, VAULT_ADDRESS],
        });

        if (amountToRepay > allowance) {
          const txId = await writeContractAsync({
            abi: ERC20_ABI,
            address: ERC20_ADDRESS,
            functionName: "approve",
            args: [VAULT_ADDRESS, amountToRepay],
          });

          await publicClient.waitForTransactionReceipt({
            hash: txId,
            confirmations: 3,
          });
        }

        const txId = await writeContractAsync({
          abi: VAULT_ABI,
          address: VAULT_ADDRESS,
          functionName: "repayLoan",
          args: [loanId],
        });

        await publicClient.waitForTransactionReceipt({
          hash: txId,
          confirmations: 2,
        });

        getNFTsIds(address);
        getAddressLoans(address);
        getLiquidityInfo();

        resolve();
      } catch (err) {
        reject(err?.shortMessage || err.message || "Something went wrong");
      }
    });
    

    toast.promise(action, {
      loading: "Transaction is in progress",
      success: "Repaid loan successfully",
      error: (error) => error,
    });
  };
  

  const onExtend = (loanId) => {
    const loanInfo = loans.filter((loan) => loan.loanId === loanId)[0];
    if (!loanInfo) return toast.error("Loan not found");

    const _interest = loanInfo.interest;
    const _amount = loanInfo.amount;

    const action = new Promise(async (resolve, reject) => {
      try {

        // multiplying each value by 1 to convert from string to number
        const amountToRepay = parseInt(
          _interest
        ).toLocaleString("fullwide", {
          useGrouping: false,
        });

        const allowance = await publicClient.readContract({
          abi: ERC20_ABI,
          address: ERC20_ADDRESS,
          functionName: "allowance",
          args: [address, VAULT_ADDRESS],
        });

        if (amountToRepay > allowance) {
          const txId = await writeContractAsync({
            abi: ERC20_ABI,
            address: ERC20_ADDRESS,
            functionName: "approve",
            args: [VAULT_ADDRESS, amountToRepay],
          });

          await publicClient.waitForTransactionReceipt({
            hash: txId,
            confirmations: 3,
          });
        }

        const txId = await writeContractAsync({
          abi: VAULT_ABI,
          address: VAULT_ADDRESS,
          functionName: "extendLoan",
          args: [loanId],
        });

        await publicClient.waitForTransactionReceipt({
          hash: txId,
          confirmations: 2,
        });

        getNFTsIds(address);
        getAddressLoans(address);
        getLiquidityInfo();

        resolve();
      } catch (err) {
        reject(err?.shortMessage || err.message || "Something went wrong");
      }
    });

    toast.promise(action, {
      loading: "Transaction is in progress",
      success: "Extended loan successfully",
      error: (error) => error,
    });
  };
  return (
    <section className="max-w-5xl mx-auto px-5 pb-10">
      <div className="bg-card shadow-[0px_2px_4px] shadow-black/10 rounded-md">
        <div className="flex items-center justify-between py-4 px-5 border-b border-black/5 gap-2" >
          <h4 className="font-medium text-xl md:text-2xl">My Loans 
          </h4>
          <h4 className="w-30 capitalize font-normal h-10 py-1">WSGB Balance: {parseFloat(wsgbBalance).toFixed(2)}
          </h4>
          <span className="gap-2">
          <RepayPopup open={isOpenRepay} onClose={() => setIsOpenRepay(false)} />
          <Button
                  onClick={() => setIsOpenRepay(true)}
                  className="w-30 capitalize font-normal h-10 py-1"
                  variant="secondary"
                  disabled={debt==0 || parseInt(debt) > parseInt(wsgbBalance)}
                >
                  Repay All
          </Button>
          <span className="gap-2 py-1"> </span>
          <ExtendPopup open={isOpenExtend} onClose={() => setIsOpenExtend(false)} />
          <Button
                  onClick={() => setIsOpenExtend(true)}
                  className="w-30 capitalize font-normal h-10 py-1"
                  variant="secondary"
                  disabled={debt==0 || parseInt(interest) > parseInt(wsgbBalance)}
                >
                  Extend All
          </Button>
          </span>
        </div>
        <div className="flex py-4 px-6 border-b border-black/5 font-medium">
          <div className="basis-1/3 md:basis-1/5">
            <p className="text-sm md:text-base">Token ID</p>
          </div>
          <div className="basis-1/3 md:basis-1/4 text-center">
            <p className="text-sm md:text-base">Debt</p>
          </div>
          <div className="hidden md:inline-block basis-1/2 text-center">
            <p className="text-sm md:text-base">Deadline</p>
          </div>
          <div className="basis-1/2 md:basis-1/4">
            <p className="text-center">Action</p>
          </div>
        </div>

        <div>
          {filteredLoans.map((loan) => (
            <TableRow key={loan.loanId} {...loan} onRepay={onRepay} onExtend={onExtend} />
          ))}
        </div>
      </div>
    </section>
  );
};
