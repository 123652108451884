import { InterestRate } from "./interestRate";
import { LendButton } from "../lendButton";
import { LiquidityInfo } from "./liquidityInfo";
import image from "@/src/assets/image.png";
import { useAccount, useWriteContract, usePublicClient } from "wagmi";
import { Button } from "@/src/components/button";
import { useHomePageContext } from "@/src/context/homePageContext";
import toast from "react-hot-toast";

import {
  ERC721_ABI,
  ERC721_ADDRESS,
} from "@/src/lib/constants";

export const Overview = () => {
  const { writeContractAsync } = useWriteContract();
  const publicClient = usePublicClient();
  const { getNFTsIds} = useHomePageContext();
  const { address } = useAccount();


const onSubmit = (event) => {
    event.preventDefault();
const faucet = new Promise(async (resolve, reject) => {
    try {
      const txId = await writeContractAsync({
        abi: ERC721_ABI,
        address: ERC721_ADDRESS,
        functionName: "faucet",
        args: [],
      });

      await publicClient.waitForTransactionReceipt({
        hash: txId,
        confirmations: 2,
      });
      getNFTsIds(address);
      resolve();
} catch (err) {
  reject( err?.shortMessage || err.message || "Something went wrong");
}

});
toast.promise(faucet, {
  loading: "Transaction is in progress",
  success: "Successfully minted NFT",
  error: (error) => error,
});
  };

  return (
    <section className="bg-transparent py-20 px-10">
      <div className="relative mx-auto my-0  max-w-5xl">
        <div className="flex items-center justify-center mx-[-0.665rem] gap-6 md:gap-0">
          <InterestRate className="mb-4 md:mb-0 hidden md:block" />
          <div className="my-4 text-center">
            <div className="h-60 w-60">
              <img src={image} alt="nft" className="rounded-md" />
            </div>
          </div>
          <div className="w-full">
            <InterestRate className="md:hidden inline-block" />
            <LiquidityInfo />
          </div>
        </div>
      </div>
      <LendButton />
    </section>
  );
};
