import { createWeb3Modal } from "@web3modal/wagmi/react";
// import { songbird } from "viem/chains";
import { http, createConfig } from "wagmi";
import { walletConnect, injected, coinbaseWallet } from "wagmi/connectors";
//  Get projectId
const projectId = "3828bedc82f5ba0e923c413da3250b1a";

// Create wagmiConfig
const metadata = {
  name: "Canary Reborn Vault",
  description: "Canary Reborn Vault",
  // url for the project: this is required
  url: "https://canary-lend.vercel.app",
};

export const chains = [
  {
    id: 19,
    name: "Songbird Canary Network",
    network: "Songbird",
    nativeCurrency: {
      decimals: 18,
      name: "SGB",
      symbol: "SGB",
    },
    rpcUrls: {
      default: {
        http: ["https://songbird.solidifi.app/ext/C/rpc"],
      },
      public: {
        http: ["https://songbird.solidifi.app/ext/C/rpc"],
      },
    },
    blockExplorers: {
      default: {
        name: "Blockscout",
        url: "https://songbird-explorer.flare.network/",
      },
    },
  },
];

export const wagmiConfig = createConfig({
  chains: chains,
  transports: {
    [chains[0].id]: http(),
  },
  connectors: [
    walletConnect({ projectId, metadata, showQrModal: false }),
    injected({ shimDisconnect: true }),
    coinbaseWallet({
      appName: metadata.name,
    }),
  ],
});
// 3. Create modal
createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeMode: "light",
  themeVariables: {
    "--w3m-border-radius-master": "0.085rem",
  },
});
