import { useAccount, usePublicClient } from "wagmi";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  ERC721_ADDRESS,
  ERC721_ABI,
  VAULT_ABI,
  VAULT_ADDRESS,
  ERC20_ABI,
  ERC20_ADDRESS,
} from "@/src/lib/constants";
import { formatEther } from "viem";

const defaultState = {
  loans: [],
  allLoans: [],
  NFTsIds: [],
  balanceOf: 0,
  liquidity: 0,
  debt: 0,
  interest:0,
  wsgbBalance:0,
  approvedAll:false,
  setNFTsIds: () => {},
  getNFTsIds: (address) => {},
  getAddressLoans: (address) => Promise.resolve(),
  getAllLoans: () => Promise.resolve(),
  getLiquidityInfo: () => Promise.resolve(),
};
const HomePageContext = createContext(defaultState);

export const HomePageContextProvider = ({ children }) => {
  const publicClient = usePublicClient();
  const { address } = useAccount();
  const [loans, setLoans] = useState([]);
  const [allLoans, setAllLoans] = useState([]);
  const [NFTsIds, setNFTsIds] = useState([]);
  const [balanceOf, setBalanceOf] = useState(0);
  const [liquidity, setLiquidity] = useState(0);
  const [debt, setDebt] = useState(0);
  const [interest, setInterest] = useState(0);
  const [wsgbBalance, setWsdgbBalance] = useState(0);
  const [approvedAll, setApprovedAll] = useState(0);

  /**
   * @dev this function gets all ERC721 tokenIds + balance for given `address`
   * @param {string} address is the address for which to read the balanceOf ERC721
   */
  const getNFTsIds = useCallback(
    async (address) => {
      try {
        const balanceOfRaw = await publicClient.readContract({
          abi: ERC721_ABI,
          address: ERC721_ADDRESS,
          functionName: "balanceOf",
          args: [address],
        });

        const balanceOf = parseInt(balanceOfRaw);

        const tokenOfOwnerByIndexPromises = [];

        for (let i = 0; i < balanceOf; i++) {
          tokenOfOwnerByIndexPromises.push(
            publicClient.readContract({
              abi: ERC721_ABI,
              address: ERC721_ADDRESS,
              functionName: "tokenOfOwnerByIndex",
              args: [address, i],
            })
          );
        }

        const tokenOfOwnerByIndex = await Promise.all(
          tokenOfOwnerByIndexPromises
        );

        setNFTsIds(tokenOfOwnerByIndex);
        setBalanceOf(balanceOf);
      } catch (err) {}
    },
    [publicClient]
  );
  const getBalance = useCallback((address) => {
    publicClient
      .readContract({
        abi: ERC20_ABI,
        address: ERC20_ADDRESS,
        functionName: "balanceOf",
        args: [address],
      })
      .then((getWsgbBalance) => {
        setWsdgbBalance(formatEther(getWsgbBalance));
      })
      .catch(console.log);
  }, [publicClient]);
  /**
   * @dev this function reads loans for given `address`
   * @param {string} address is the address for which to read loans information
   */
  const getAddressLoans = useCallback(
    async (address) => {
      try {
        const loanIds = await publicClient.readContract({
          abi: VAULT_ABI,
          address: VAULT_ADDRESS,
          functionName: "getAddressToLoansOffers",
          args: [address],
        });

        const loansInfoPromise = loanIds.map((loanId) =>
          publicClient.readContract({
            abi: VAULT_ABI,
            address: VAULT_ADDRESS,
            functionName: "loans",
            args: [loanId],
          })
        );
        const loansInfo = await Promise.all(loansInfoPromise);

        setLoans(
          loansInfo.map((loanInfo, index) => ({
            loanId: parseInt(loanIds[index]),
            borrower: loanInfo[0],
            lender: loanInfo[1],
            nftAddress: loanInfo[2],
            nftId: parseInt(loanInfo[3]),
            created: parseInt(loanInfo[4]),
            duration: parseInt(loanInfo[5]),
            deadline: parseInt(loanInfo[6]),
            amount: parseInt(loanInfo[7]).toLocaleString("fullwide", {
              useGrouping: false,
            }),
            interest: parseInt(loanInfo[8]).toLocaleString("fullwide", {
              useGrouping: false,
            }),
            status: loanInfo[9],
            extensions: parseInt(loanInfo[10]),
          }))
        );
      } catch (err) {
        console.log(err);
      }
    },
    [publicClient]
  );
  const getAllLoans = useCallback(
    async () => {
      try {
        const loanIds = await publicClient.readContract({
          abi: VAULT_ABI,
          address: VAULT_ADDRESS,
          functionName: "getAllLoans",
          args: [],
        });



        const loansInfoPromise = loanIds.map((loanId) =>
          publicClient.readContract({
            abi: VAULT_ABI,
            address: VAULT_ADDRESS,
            functionName: "loans",
            args: [loanId],
          })
        );
        const loansInfo = await Promise.all(loansInfoPromise);

        setAllLoans(
          loansInfo.map((loanInfo, index) => ({
            loanId: parseInt(loanIds[index]),
            borrower: loanInfo[0],
            lender: loanInfo[1],
            nftAddress: loanInfo[2],
            nftId: parseInt(loanInfo[3]),
            created: parseInt(loanInfo[4]),
            duration: parseInt(loanInfo[5]),
            deadline: parseInt(loanInfo[6]),
            amount: parseInt(loanInfo[7]).toLocaleString("fullwide", {
              useGrouping: false,
            }),
            interest: parseInt(loanInfo[8]).toLocaleString("fullwide", {
              useGrouping: false,
            }),
            status: loanInfo[9],
            extensions: parseInt(loanInfo[10]),
          }))
        );
      } catch (err) {
        console.log(err);
      }
    },
    [publicClient]
  );

  const getLiquidityInfo = useCallback(() => {
    publicClient
      .readContract({
        abi: VAULT_ABI,
        address: VAULT_ADDRESS,
        functionName: "getLiquidity",
        args: [],
      })
      .then((balanceOf) => {
        setLiquidity(formatEther(balanceOf));
      })
      .catch(console.log);
  }, [publicClient]);

  const getApprovedAll =  useCallback((address) => {
    publicClient.readContract({
    abi: ERC721_ABI,
    address: ERC721_ADDRESS,
    functionName: "isApprovedForAll",
    args: [address, VAULT_ADDRESS],
  }).then((approvedAll) => {
    setApprovedAll(approvedAll);
  })
  .catch(console.log);
}, [publicClient]);


  const getDebt = useCallback((address) => {
    publicClient
      .readContract({
        abi: VAULT_ABI,
        address: VAULT_ADDRESS,
        functionName: "getTotalDebt",
        args: [address],
      })
      .then((getTotalDebt) => {
        setDebt(formatEther(getTotalDebt));
      })
      .catch(console.log);
  }, [publicClient]);

  const getTotalInterest = useCallback((address) => {
    publicClient
      .readContract({
        abi: VAULT_ABI,
        address: VAULT_ADDRESS,
        functionName: "getTotalInterest",
        args: [address],
      })
      .then((getTotalInterest) => {
        setInterest(formatEther(getTotalInterest));
      })
      .catch(console.log);

  }, [publicClient]);
  useEffect(() => {
    if (address) {
      getAddressLoans(address);
      getNFTsIds(address);
    }
  }, [address, getAddressLoans, getNFTsIds]);

  return (
    <HomePageContext.Provider
      value={{
        loans,
        allLoans,
        NFTsIds,
        balanceOf,
        liquidity,
        debt,
        interest,
        wsgbBalance,
        approvedAll,
        setNFTsIds,
        getNFTsIds,
        getAddressLoans,
        getApprovedAll,
        getLiquidityInfo,
        getDebt,
        getBalance,
        getTotalInterest,
        getAllLoans,
      }}
    >
      {children}
    </HomePageContext.Provider>
  );
};

export const useHomePageContext = () => useContext(HomePageContext);
