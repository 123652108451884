import { useState } from "react";
import { formatEther } from "viem";
import { Button } from "@/src/components/button";
import { ExpandIcon } from "@/src/assets/icons";
import { ERC20_SYMBOL } from "@/src/lib/constants";
import { cn } from "@/src/lib/utils";
import { useHomePageContext } from "@/src/context/homePageContext";
import { useAccount } from "wagmi";

export const TableRow = ({
  loanId,
  nftId,
  interest,
  status,
  extensions,
  deadline,
  amount,
  onRepay,
  onExtend,
}) => {
  const { address } = useAccount();
  const {debt, getDebt, wsgbBalance, getBalance } =
  useHomePageContext();
  getDebt(address);
  getBalance(address);
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className="group">
      <span className="flex py-5 px-6 text-base border-b border-b-black/5 border-l-transparent border-l-2 group-hover:border-l-gray-500 transition-all">
        <div className="basis-1/3 md:basis-1/5 flex items-center">
          <p className="text-sm md:text-base">{nftId}</p>
        </div>

        <div className="basis-1/3 md:basis-1/4 flex items-center justify-center">
          <p className="text-sm md:text-base">
            {formatEther(parseInt(interest) + parseInt(amount))} {ERC20_SYMBOL}
          </p>
        </div>

        <div className="basis-1/2 truncate hidden md:flex items-center justify-center">
          <p className="text-sm md:text-base">
            {new Date(deadline * 1000).toLocaleString()}
          </p>
        </div>

        <div className="basis-1/4 hidden md:flex gap-2 items-center justify-center">
          {console.log(wsgbBalance)}
          {console.log(formatEther(parseInt(interest) + parseInt(amount)))}
          <Button
            // if status is not equal to 2 that means the loan has been repaid or liquidated
            disabled={parseInt(status) !== 2 || (formatEther(parseInt(interest) + parseInt(amount))) > parseInt(wsgbBalance)}
            onClick={onRepay.bind(this, loanId)}
            className="w-20 capitalize font-normal h-10"
            variant="secondary"
          >
            Repay
          </Button>
          <Button
            disabled={parseInt(status) !== 2 || parseInt(extensions) > 2 || formatEther(parseInt(interest)) > parseInt(wsgbBalance)}
            onClick={onExtend.bind(this, loanId)}
            className="w-20 capitalize font-normal h-10"
            variant="secondary"
          >
            Extend
          </Button>
        </div>

        <div className="basis-1/2 inline-flex justify-center md:hidden">
          <button
            className={cn("transition-all", {
              "rotate-180": isExpanded,
            })}
            onClick={() => setIsExpanded((prev) => !prev)}
          >
            <ExpandIcon className="h-5 w-5" />
          </button>
        </div>
      </span>

      {isExpanded && (
        <div
          className={cn("md:hidden", {
            hidden: !isExpanded,
          })}
        >
          <span className="group-hover:border-l-gray-500 flex py-5 px-6 text-base border-b border-b-black/5 border-l-transparent border-l-2 hover:border-l-gray-500 transition-all">
            <div className="basis-1/2">
              <p className="text-sm md:text-base font-medium ">Deadline</p>
            </div>
            <div className="basis-1/2" />
          </span>
          <span className="group-hover:border-l-gray-500 flex py-5 px-6 text-base border-b border-b-black/5 border-l-transparent border-l-2 hover:border-l-gray-500 transition-all">
            <div className="basis-1/2 flex items-center">
              <p className="text-sm md:text-base">
                {new Date(deadline * 1000).toLocaleString()}
              </p>
            </div>
            <div className="basis-1/2 flex gap-2 items-center justify-center">
              <Button
                // if status is not equal to 2 that means the loan has been repaid or liquidated
                disabled={parseInt(status) !== 2 || (formatEther(parseInt(interest) + parseInt(amount))) > parseInt(wsgbBalance)}
                onClick={onRepay.bind(this, loanId)}
                className="w-20 capitalize font-normal h-10"
                variant="secondary"
              >
                Repay
              </Button>
              <Button
                disabled={parseInt(status) !== 2 || parseInt(extensions) > 2 || formatEther(parseInt(interest)) > parseInt(wsgbBalance)}
                onClick={onExtend.bind(this, loanId)}
                className="w-20 capitalize font-normal h-10"
                variant="secondary"
              >
                Extend
              </Button>
            </div>
          </span>
        </div>
      )}
    </div>
  );
};
