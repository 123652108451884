import React, { useRef } from "react";
import { useAccount, usePublicClient, useWriteContract } from "wagmi";
import toast from "react-hot-toast";
import { useHomePageContext } from "@/src/context/homePageContext";
import * as Dialog from "@radix-ui/react-dialog";
import { Button } from "@/src/components/button";

import {
  VAULT_ABI,
  VAULT_ADDRESS,
  ERC20_ABI,
  ERC20_ADDRESS,
} from "@/src/lib/constants";

export const ExtendPopup = ({ open, onClose = () => null }) => {
  const publicClient = usePublicClient();
  const { writeContractAsync } = useWriteContract();
  const { address } = useAccount();
  const { NFTsIds, interest, getTotalInterest, getNFTsIds, getAddressLoans, getLiquidityInfo } =
    useHomePageContext();

  // hook to prevent tx call if one is already in progress
  const isCallInProgress = useRef(false);
  getTotalInterest(address);

  const onExtendAll = () => {
    onClose();
    const action = new Promise(async (resolve, reject) => {
      try {
        const totalInterest = parseInt(
          await publicClient.readContract({
            abi: VAULT_ABI,
            address: VAULT_ADDRESS,
            functionName: "getTotalInterest",
            args: [address],
          })
        ).toLocaleString("fullwide", {
          useGrouping: false,
        });

        const allowance = await publicClient.readContract({
          abi: ERC20_ABI,
          address: ERC20_ADDRESS,
          functionName: "allowance",
          args: [address, VAULT_ADDRESS],
        });

        if (totalInterest > parseInt(allowance)) {
          const txId = await writeContractAsync({
            abi: ERC20_ABI,
            address: ERC20_ADDRESS,
            functionName: "approve",
            args: [VAULT_ADDRESS, totalInterest],
          });

          await publicClient.waitForTransactionReceipt({
            hash: txId,
            confirmations: 3,
          });
        }

        const txId = await writeContractAsync({
          abi: VAULT_ABI,
          address: VAULT_ADDRESS,
          functionName: "extendAll",
        });

        await publicClient.waitForTransactionReceipt({
          hash: txId,
          confirmations: 2,
        });

        getAddressLoans(address);
        getLiquidityInfo();
        getNFTsIds(address);

        resolve();
      } catch (err) {
        reject(err?.shortMessage || err.message || "Something went wrong");
      }
    });
    

    toast.promise(action, {
      loading: "Transaction is in progress",
      success: "Extend loans successfully",
      error: (error) => error,
    });
  };
  
  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-black/20 data-[state=open]:animate-overlay-show fixed inset-0" />
        <Dialog.Content className="data-[state=open]:animate-content-show fixed top-[50%] left-[50%] max-h-[85vh] w-auto max-w-[90vw] -translate-x-1/2 -translate-y-1/2 rounded-sm bg-white p-6 focus:outline-none">
          <Dialog.Title className="m-0 text-lg font-semibold">
           Extend Loans
          </Dialog.Title>
          <Dialog.Description className="text-mauve11 mt-[10px] mb-5 text-[15px] leading-normal">
            <div>In order to extend the loans, you will need to pay the interest upfront. </div>
            <div>Loans can be extended 3 times, each time for 7 days. </div>
            <div>Total interest: {parseFloat(interest).toFixed(2)}</div>
            <div>* If no loans are eligible for extending, the below action will have no effect.</div>
          </Dialog.Description>

            <div className="mt-[25px] flex justify-end gap-3">
              <Button onClick={onExtendAll.bind(this)}type="submit" className="w-auto capitalize min-w-32">
                Extend Loans
              </Button>

              <Button
                type="button"
                variant="secondary"
                className="w-auto capitalize min-w-32"
                onClick={onClose}
              >
                Cancel
              </Button>
            </div>
          
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
